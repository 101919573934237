import "core-js/modules/es.array.push.js";
import { ref } from 'vue';
import { showSuccessToast, showToast } from "vant";
import { checkPassword } from '@/api/login';
import { login, sendSms } from '@/api/device';
import axios from "axios";
import dict from "@/utils/dict";
export default {
  name: 'login',
  data() {
    return {
      supportPhone: dict.get('phone', 'support'),
      // 表单绑定数据
      formData: {
        phoneNumber: '',
        password: '',
        smsCode: ''
      },
      phonePattern: /^1[3-9]\d{9}$/,
      countdown: 60,
      // 初始倒计时为60秒
      isCountdownActive: false,
      // 是否在倒计时中
      countdownText: '发送验证码',
      // 按钮显示文本
      countdownInterval: null,
      // 保存计时器引用
      smsCodePattern: /^.{4}$/,
      passwordPattern: /^.{8}$/
    };
  },
  methods: {
    // 发送验证码方法
    async sendVerificationCode() {
      // 判断手机号是否为空
      if (!this.formData.phoneNumber) {
        showToast('请输入手机号');
        return;
      }
      // 正则表达式检查手机号格式（假设为中国大陆手机号，11位数字，且以1开头）
      const phoneRegex = /^1[3-9]\d{9}$/;
      if (!phoneRegex.test(this.formData.phoneNumber)) {
        showToast('请输入正确的手机号');
        return;
      }
      // // 先验证口令
      const res = await checkPassword(this.formData.password); // 等待口令验证结果

      if (res.data === false) {
        showToast("口令不正确");
        return; // 口令不正确，停止后续操作
      }
      const smsData = {
        mobile: this.formData.phoneNumber,
        scene: 1
      };
      // 发送手机验证码
      const sms = await sendSms(smsData);
      if (sms.data === false || sms.data === null) {
        // showToast("验证码发送失败")
        showToast(sms.msg);
        return;
      }

      // 启动倒计时
      this.startCountdown();

      // 通过验证后可以继续发送验证码的操作
      showToast('验证码已发送');
    },
    // 启动倒计时
    startCountdown() {
      this.isCountdownActive = true;
      this.countdownText = `${this.countdown}秒`;

      // 每秒更新一次倒计时
      this.countdownInterval = setInterval(() => {
        this.countdown--;
        this.countdownText = `${this.countdown}秒`;

        // 倒计时结束
        if (this.countdown <= 0) {
          clearInterval(this.countdownInterval); // 清除定时器
          this.isCountdownActive = false; // 结束倒计时
          this.countdown = 60; // 重置倒计时
          this.countdownText = '重新发送'; // 恢复按钮文本
        }
      }, 1000);
    },
    // 在线客服方法
    contactSupport() {
      // window.location.href = 'https://work.weixin.qq.com/kfid/kfccf9ffd8c2caa3723';
      window.open("https://work.weixin.qq.com/kfid/kfccf9ffd8c2caa3723", "_blank");
    },
    // 表单提交时的操作
    async onSubmit(values) {
      // 先验证口令
      const res = await checkPassword(this.formData.password); // 等待口令验证结果
      if (res.data === false) {
        showToast("口令不正确");
        return; // 口令不正确，停止后续操作
      }
      const loginData = {
        mobile: this.formData.phoneNumber,
        code: this.formData.smsCode
      };
      const ress = await login(loginData);
      if (ress.data === null) {
        showToast(ress.msg);
      }

      // 口令验证通过，继续执行下面的操作
      if (res.data && ress.data && res.data != null && ress.data != null) {
        const mobile = this.formData.phoneNumber;
        // this.formData.phoneNumber = '';
        this.formData.password = '';
        this.formData.smsCode = '';
        showSuccessToast("登录成功");
        this.$router.push({
          path: '/OMDevice',
          query: {
            phoneNumber: this.formData.phoneNumber
          }
        });
      } else {
        showToast("登录失败请重试");
      }
    }
  }
};