import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'Login',
    path: '/Login',
    component: () => import('./view/system/Login'),
    props: true, // 启用props传递
    meta:{
      title: '登录'
    }
  },
  {
    name: 'MainEquipment',
    path: '/MainEquipment',
    component: () => import('./view/system/MainEquipment'),
    props: true, // 启用props传递
    meta:{
      title: '设备页面'
    }
  },
  {
    name: 'PlaySelection',
    path: '/PlaySelection',
    component: () => import('./view/system/PlaySelection'),
    props: true, // 启用props传递
    meta:{
      title: '播放选择'
    }
  },
  {
    name: 'OMLogin',
    path: '/OMLogin',
    component: () => import('./view/OperationsManagement/login.vue'),
    props: true, // 启用props传递
    meta:{
      title: 'OM登录'
    }
  },
  {
    name: 'OMDevice',
    path: '/OMDevice',
    component: () => import('./view/OperationsManagement/device.vue'),
    props: true, // 启用props传递
    meta:{
      title: 'OMDevice'
    }
  },
  {
    path: '/',
    component: () => import('./view/OperationsManagement/login.vue'),
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
