import request from '@/utils/http';


// 获取设备信息
export function getDeviceInfo(mobile, deviceNo) {
    return request({
        url: '/system/operationAuth/getDeviceInfo',
        method: 'get',
        params: {
            mobile: mobile,
            deviceNo: deviceNo
        }
    });
}

// 获取最新设备信息
export function getLatestData(id) {
    return request({
        url: '/system/operationAuth/getLatestData',
        method: 'get',
        params: { id }
    });
}

// 播放音频
export function playRing(soundDevicePlayReqVO) {
    return request({
        url: '/system/operationAuth/play_ring',
        method: 'post',
        data: soundDevicePlayReqVO
    });
}

// 停止播放音频
export function playOverRing(soundDevicePlayReqVO) {
    return request({
        url: '/system/operationAuth/playOver_ring',
        method: 'post',
        data: soundDevicePlayReqVO
    });
}

export function updateDeviceVolume(deviceVolume) {
    return request({
        url: '/system/operationAuth/updateDeviceVolume',
        method: 'put',
        data: deviceVolume
    })
}

// 修改设备
export function updateDevice(soundDeviceUpdateOperationVO) {
    return request({
        url: '/system/operationAuth/updateDevice',
        method: 'put',
        data: soundDeviceUpdateOperationVO
    });
}
// 发送短信
export function sendSms(data) {
    return request({
        url: '/system/operationAuth/send-sms-code',
        method: 'post',
        data: data
    })
}

// 登录
export function login(data) {
    return request({
        url: '/system/operationAuth/sms-login',
        method: 'post',
        data: data
    })
}

export function soundInstalled(soundInstalled) {
    return request({
        url: 'system/soundInstall/insert',
        method: 'post',
        data: soundInstalled
    })
}
