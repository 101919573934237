// utils/dict.js

const dict = {
    // 定义数据字典
    phone: {
        support: '0592-5858568', // 示例：客服电话
        sales: '0592-1234567', // 示例：销售电话
    },
    deviceStatus: {
        0: '离线',
        1: '在线',
        2: '故障',
    },
    userRole: {
        admin: '管理员',
        user: '普通用户',
        guest: '访客',
    },
    orderStatus: {
        pending: '待处理',
        completed: '已完成',
        cancelled: '已取消',
    },

    // 获取字典值的通用方法
    get(dictName, key) {
        const dictionary = this[dictName];
        if (!dictionary) {
            console.warn(`数据字典 "${dictName}" 不存在`);
            return null;
        }
        const value = dictionary[key];
        if (value === undefined) {
            console.warn(`数据字典 "${dictName}" 中的键 "${key}" 不存在`);
            return null;
        }
        return value;
    },
};

export default dict;
