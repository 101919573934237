import request from '@/utils/http';

// 获取口令
export function getPassword(id) {
    return request({
        url: '/system/password/get?id=' + id,
        method: 'get'
    })
}

// 获取口令分页
export function getPasswordPage(query) {
    return request({
        url: '/system/password/page',
        method: 'get',
        params: query
    })
}

// 验证口令是否存在
export function checkPassword(password) {
    return request({
        url: '/system/password/check',
        method: 'get',
        params: { password }  // 传递 password 查询参数
    });
}
