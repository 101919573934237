import axios from 'axios';
import { showDialog } from 'vant';

// 创建axios实例
const service = axios.create({
    baseURL: "https://opex.guodun.org.cn/admin-api/",  // 后端的基础路径
    timeout: 3000,  // 请求超时时间
    withCredentials: false,  // 禁用 Cookie
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 设置租户ID，假设租户ID是 1
        config.headers['tenant-id'] = 1;
        return config;
    },
    error => {
        // 请求错误
        console.error('请求错误:', error);
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        // 响应成功处理
        return response.data;  // 返回响应体中的数据部分
    },
    error => {
        // 响应错误处理
        console.error('响应错误:', error);
        // 弹出错误提示
        showDialog({
            title: '错误',
            message: '请求失败，请稍后重试！',
        });
        return Promise.reject(error);
    }
);

// 导出axios实例
export default service;
