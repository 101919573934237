import "core-js/modules/es.array.push.js";
import { showDialog, showToast } from "vant";
import { getDeviceInfo, getLatestData, playRing, playOverRing, updateDevice, updateDeviceVolume, soundInstalled } from '@/api/device';
import dict from '@/utils/dict';
export default {
  name: 'device',
  data() {
    return {
      supportPhone: dict.get('phone', 'support'),
      phoneNumber: '',
      formData: {
        mobile: '',
        deviceNo: ''
      },
      phonePattern: /^1[3-9]\d{9}$/,
      deviceNoPattern: /^[A-Za-z0-9]{1,10}$/,
      deviceNotFound: true,
      // 默认显示"查询不到相关设备"
      deviceInfo: {
        name: '设备ABC',
        // 假设设备名称
        onlineStatus: '是',
        // 假设在线状态
        volume: 30,
        deviceCode: ""
      },
      deviceInfoStatus: "未找到该设备",
      audioPlayed: false,
      textPlayed: false,
      showPopup: false,
      audio: null,
      // 保存音频对象
      speechSynthesisUtterance: null,
      // 保存语音合成对象
      loading: false,
      loading2: false,
      installed: false
    };
  },
  created() {
    // ToDo 目前显式传递登录页参数
    this.phoneNumber = this.$route.query.phoneNumber;
    console.log('接收到的手机号:', this.phoneNumber);
  },
  methods: {
    onSubmit() {
      this.installed = false;
      this.audioPlayed = false;
      this.textPlayed = false;
      console.log('提交查询设备表单');
      getDeviceInfo(this.formData.mobile, this.formData.deviceNo).then(res => {
        if (res.data) {
          this.deviceInfo.id = res.data.id;
          this.deviceInfo.name = res.data.name;
          this.deviceInfo.onlineStatus = res.data.isOnline ? "在线" : "离线";
          this.deviceInfo.volume = res.data.aoVolume;
          this.deviceInfo.deviceCode = res.data.deviceCode;
          this.deviceNotFound = false; // 切换设备是否找到的状态
          this.deviceInfoStatus = res.msg;
        } else {
          this.deviceInfo = {
            name: '',
            onlineStatus: '',
            volume: "",
            deviceCode: ""
          };
          this.deviceInfoStatus = res.msg;
          this.deviceNotFound = true;
          showToast(res.msg);
        }
      });
    },
    showDeviceInfo() {
      console.log('显示如何查看设备编码');
      this.showPopup = true;
    },
    refreshDeviceStatus() {
      console.log('刷新设备状态');
      getLatestData(this.deviceInfo.id).then(res => {
        if (res.data) {
          this.deviceInfo.id = res.data.id;
          this.deviceInfo.name = res.data.name;
          this.deviceInfo.onlineStatus = res.data.isOnline ? "在线" : "离线";
          this.deviceInfo.volume = res.data.aoVolume;
          showToast("刷新成功");
        } else {
          // showToast(res.msg)
          showToast("刷新失败");
        }
      });
    },
    // 改变音量状态
    changeVolume(e) {
      console.log(this.deviceInfo.volume);
      console.log("e的变化", this.deviceInfo.volume);
      const deviceVolume = {
        id: this.deviceInfo.id,
        aoVolume: this.deviceInfo.volume
      };
      updateDeviceVolume(deviceVolume).then(res => {
        console.log(res);
        if (res.data && res.data !== null) {} else {
          showToast(res.msg);
        }
      });
    },
    playAudio() {
      this.loading = true;
      // 设定开始音频传递对象
      const device = {
        playDeviceId: this.deviceInfo.id,
        playDeviceCode: this.deviceInfo.deviceCode,
        playType: 0,
        content: '智播小哨，在校门口上下学时段通过音频方式宣导关于生命安全、心理健康、爱国主义及法治教育等方面的知识。',
        ringId: 1813,
        vcn: "xiaoyan",
        speed: 50
      };
      playRing(device).then(res => {
        if (res.data && res.data !== null) {
          this.audioPlayed = true;
        } else {
          showToast(res.msg);
        }
        this.loading = false;
      });
    },
    playText() {
      this.loading2 = true;
      const device = {
        playDeviceId: this.deviceInfo.id,
        playDeviceCode: this.deviceInfo.deviceCode,
        playType: 1,
        content: '智播小哨，在校门口上下学时段通过音频方式宣导关于生命安全、心理健康、爱国主义及法治教育等方面的知识。',
        ringId: 1813,
        vcn: "xiaoyan",
        speed: 50
      };
      playRing(device).then(res => {
        if (res.data && res.data !== null) {
          this.textPlayed = true;
        } else {
          showToast(res.msg);
        }
        this.loading2 = false;
      });
    },
    stopPlay() {
      const device = {
        playDeviceId: this.deviceInfo.id,
        playType: 0
      };
      playOverRing(device).then(res => {
        if (res.data && res.data !== null) {} else {
          showToast(res.msg);
        }
      });
    },
    contactSupport() {
      window.open("https://work.weixin.qq.com/kfid/kfccf9ffd8c2caa3723", "_blank");
    },
    back() {
      this.$router.push('/OMLogin');
    },
    confirm() {
      showDialog({
        title: '温馨提示',
        message: '确定播放有声音吗？',
        showCancelButton: true,
        confirmButtonText: "有声音",
        cancelButtonText: '无声音'
      }).then(() => {
        console.log("确认");
        // 修改安装状态
        this.installed = true;
        // 记录音柱安装表
        const soundInstalledItem = {
          mobile: this.phoneNumber,
          deviceCode: this.deviceInfo.deviceCode
        };
        console.log(soundInstalledItem);
        soundInstalled(soundInstalledItem).then(res => {
          console.log(res);
        });
        // 设备就不能再被运维端查询到
        const deviceOver = {
          id: this.deviceInfo.id,
          isOperation: 0
        };
        updateDevice(deviceOver).then(res => {
          console.log(res);
          // 清楚页面数据
          this.deviceInfo.id = '';
          // this.deviceInfo.name = ''
          this.deviceInfo.onlineStatus = '';
          this.deviceInfo.volume = '';
          this.deviceNotFound = true;
        });
      }).catch(() => {
        console.log('取消');
        showDialog({
          message: `如设备有问题，请致电 ${dict.get('phone', 'support')}`
        });
      });
    }
  }
};